import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./stroe"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faKey, faPlus, faSearch, faUser,faBuilding,faStore,faClipboardList,faCalendar, faCalendarAlt, faBolt, faUserTie, faClipboardCheck, faUserEdit, faCalendarCheck, faBusinessTime, faInfo, faClock, faMapMarked, faPoll } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ElButton,ElInput,ElMessage,ElRow} from 'element-plus'
import { vLoading } from "element-plus/es/components/loading/src/directive";
import axios from "axios";

library.add(faKey,faUser,faSearch,faPlus,faBuilding,faStore,faClipboardList,faCalendar,faCalendarAlt,faBolt,faUserTie,faClipboardCheck,faUserEdit,faCalendarCheck,faBusinessTime,faInfo,faClock,faMapMarked,faPoll)
const app = createApp(App)
app.use(router)
app.directive('load', vLoading)
app.use(store)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(ElButton,ElInput,ElRow,ElMessage)
app.mount("#app")
axios.defaults.withCredentials = true