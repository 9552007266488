import { createRouter, createWebHistory } from "vue-router";
import { uStore } from "@/stroe";
const store = uStore()
const routes = [
    {
        path: "/Login",
        name: "Login",
        component: () => import("@/views/Login.vue"),
        meta: {
            title: "登陆",
        },
    },
    {
        path: "/",
        name: "MainPage",
        component: () => import("@/views/MainPage.vue"),
        meta: {
            title: "主页",
            isAuth: true
        },children:  [
            {
                path: "Application",
                name: "Application",
                component: () => import("@/views/ApplicationPage.vue"),
                meta: {
                    title: "加急申请",
                    isAuth: true
                }
            }, {
                path: "UrgentAudit",
                name: "UrgentAudit",
                component: () => import("@/views/UrgentAudit.vue"),
                meta: {
                    title: "加急管理",
                    isAuth: true
                }
            },{
                path: "UrgentSetting",
                name: "UrgentSetting",
                component: () => import("@/views/UrgentSetting.vue"),
                meta: {
                    title: "加急配置",
                    isAuth: true
                }
            },
        ]
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.isAuth){
        if (store.state.loginUser){
            next()
        }
        else{
            alert("需要登陆方可使用");
            router.push("/Login")
        }
    }
    else{
        next();
    }
})

router.afterEach((to, from) => {
    const title = to.meta.title || "";
    document.title = "居里亚加急-" + title;
})

export default router