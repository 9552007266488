import { createStore } from "vuex";
import axios from "axios";
const state = {
    companyName: "居里亚",
    fullCompanyName: "成都居里亚家居有限公司",
    BaseUrl : "https://api.juliazzy.com/Urgent/",
    loginUrl : "https://api.juliazzy.com/Login",
    // BaseUrl: "http://127.0.0.1:5050/Urgent/",
    // loginUrl: "http://127.0.0.1:5050/Login",
    userRule: [],
    isAdmin:false
}

const actions = {
    addLoginUser(content,UserInfo){
        content.commit("AddLoginUser",UserInfo)
    },
    IsAdmin(content){
        axios.get(store.state.BaseUrl +"IsAdmin").then(response=>{
            content.commit("InitAdmin",response.data.admin)
        })
    },
    loginOut(content){
        axios.get(store.state.BaseUrl + "LoginOut")
        content.commit("LoginOut")
    }
}


const mutations ={
    AddLoginUser(state,UserInfo){
        state.loginUser = UserInfo
    },
    LoginOut(state){
        delete state.loginUser;
    },
    InitUserRule(state,rule){
        state.userRule = rule;
    },
    InitAdmin(state,adminSate){
        state.isAdmin = adminSate;
    }
}
const store = createStore({
    state,
    actions,
    mutations,
})

export function uStore(){
    return store;
}
export default store